import React from 'react'
import footerImg from '../assets/img/footerImg.png'
import googleIcon from '../assets/img/googleIcon.svg'
import twitterIcon from '../assets/img/twitterIcon.svg'
import facebookIcon from '../assets/img/facebookIcon.svg'
import linkdinIcon from '../assets/img/linkdinIcon.svg'
import printerestIcon from '../assets/img/printerestIcon.svg'
import youtubeIcon from '../assets/img/youtubeIcon.svg'

export default function Footer({color}) {
    return (
        <>
            <footer>
                <div className={`w-full ${color === 'gray' ? 'bg-[#1E1E1E]' : 'bg-[#060012]' } `}>

                    <div className='py-10 container-2000 container-1200 h-full text-white'>

                        <div className='sm:flex justify-between'>
                            <div className='mt-10 space-y-8'>
                                <p className='sm:text-xl font-semibold'>Want to discuss a project ?</p>
                                <p className='title2'>Let’s get in touch &#x2192;</p>
                                <p className='sm:text-xl font-semibold'>randomeamil@gmail.com</p>
                                <div className='flex sm:space-x-3 space-x-2'>
                                    <img src={googleIcon} alt="" className='sm:h-[64px] h-[40px] sm:w-[64px] w-[40px] ' />
                                    <img src={twitterIcon} alt="" className='sm:h-[64px] h-[40px] sm:w-[64px] w-[40px] '/>
                                    <img src={facebookIcon} alt="" className='sm:h-[64px] h-[40px] sm:w-[64px] w-[40px] '/>
                                    <img src={linkdinIcon} alt="" className='sm:h-[64px] h-[40px] sm:w-[64px] w-[40px] '/>
                                    <img src={printerestIcon} alt="" className='sm:h-[64px] h-[40px] sm:w-[64px] w-[40px] '/>
                                    <img src={youtubeIcon} alt="" className='sm:h-[64px] h-[40px] sm:w-[64px] w-[40px] '/>
                                </div>
                            </div>
                            <div className='h-full flex items-center justify-center'>
                                {/* <img src={footerImg} alt="" className='md:h-[455px] h-[300px] md:w-[455px] w-[300px]'/> */}
                                <img src={footerImg} alt="" className='lg:size-[455px] md:size-[355px] sm:size-[255px] size-[155px]'/>
                            </div>
                        </div>
                        <p className='opacity-50 lg:mt-0 mt-10'>All right reserved Cubezytech 2022</p>

                    </div>
                </div>
            </footer>
        </>
    )
}
