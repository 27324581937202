import React, { useState } from 'react';
import mainLogo from '../assets/img/mainLogo.svg'
import { Link, NavLink, useNavigate } from 'react-router-dom';

const Navbar = ({ mode }) => {
    const navigate = useNavigate()
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <nav className='w-screen fixed top-0 min-[3000px]:h-[120px] h-[80px] bg-[#060012] z-20'>

            <div className='container-2000 container-1200 h-full flex items-center justify-between text-white border-b border-white/5  shadow-2xl'>

                <div className='flex items-center space-x-2'>
                    <Link to={'/'} className="flex items-center space-x-2">
                        <img src={mainLogo} alt="cubezyTech Logo" className='h-12 w-12' />
                        <p className='font-bold sm:text-2xl leading-none'>Cubezy <br /> Tech</p>
                    </Link>


                    {/* <div className="h-[78px] w-0 border border-white/5 sm:block hidden"></div> */}
                    {/* {
                        mode === 'home' &&
                        <>
                            <div className="h-12 w-0 border border-gray-500 sm:block hidden"></div>
                            <p className="text-xs font-bold sm:block hidden">
                                DIGITAL <br /> DESIGN <br /> AGENCY
                            </p>
                        </>
                    } */}
                </div>
                <div className='hidden md:block min-[3000px]:text-2xl'>
                    <ul className='flex lg:space-x-10 space-x-5 text-gray1'>
                        <li className='hover:text-white'>
                            <NavLink
                                to='/AboutUs'
                                className={({ isActive }) =>
                                    isActive ? 'text-white hover:text-white' : 'hover:text-white text-gray1'
                                }
                            >
                                About
                            </NavLink>
                        </li>
                        <li className='hover:text-white'>
                            <NavLink
                                to='/Services'
                                className={({ isActive }) =>
                                    isActive ? 'text-white hover:text-white' : 'hover:text-white text-gray1'
                                }
                            >
                                Services
                            </NavLink>
                        </li>
                        <li className='hover:text-white'>
                            <NavLink
                                to='/Work'
                                className={({ isActive }) =>
                                    isActive ? 'text-white hover:text-white' : 'hover:text-white text-gray1'
                                }
                            >
                                Work
                            </NavLink>
                        </li>
                        <li className='hover:text-white'>
                            <NavLink
                                to='/Blog'
                                className={({ isActive }) =>
                                    isActive ? 'text-white hover:text-white' : 'hover:text-white text-gray1'
                                }
                            >
                                Blog
                            </NavLink>
                        </li>
                    </ul>


                </div>

                <div className='hidden md:block'>
                    <button className='bg-blue-600 hover:bg-blue-800 text-[16px] px-10 py-3 rounded' onClick={() => { navigate('/ContactUs') }}>
                        Get in touch
                    </button>
                </div>

                {/* Hamburger Menu Button */}
                <div className='md:hidden'>
                    <button
                        className="flex flex-col space-y-2 items-center justify-center focus:outline-none"
                        onClick={toggleMenu}
                    >
                        <div className="w-8 h-1 bg-gray-500 rounded"></div>
                        <div className="w-8 h-1 bg-gray-500 rounded"></div>
                        <div className="w-8 h-1 bg-gray-500 rounded"></div>
                    </button>
                </div>
            </div>

            {/* Dropdown Menu */}
            {menuOpen && (
                <div className='md:hidden bg-[#060012] text-white absolute top-[80px] left-0 w-full'>
                    <ul className='flex flex-col items-center space-y-5 py-5'>
                        <li onClick={(()=>{navigate('/AboutUs');setMenuOpen(!menuOpen)})}>About</li>
                        <li onClick={(()=>{navigate('/Services');setMenuOpen(!menuOpen)})}>Services</li>
                        <li onClick={(()=>{navigate('/Work');setMenuOpen(!menuOpen)})}>Work</li>
                        <li onClick={(()=>{navigate('/Blog');setMenuOpen(!menuOpen)})}>Blog</li>
                        <button className='bg-blue-600 text-[16px] px-10 py-3 rounded' onClick={() => { navigate('/ContactUs');setMenuOpen(!menuOpen) }}>
                            Get in touch
                        </button>
                    </ul>
                </div>
            )}

        </nav>
    );
};

export default Navbar;
