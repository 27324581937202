import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from './Footer';
import ReactGA from 'react-ga4';
import ScrollToTop from './ScrollToTop';

export default function Layout() {
    const location = useLocation();
    const grayFooterPaths = ['/', '/AboutUs', '/Work'];

    const [analyticsEnabled, setAnalyticsEnabled] = useState(false);
    const [shouldShowBanner, setShouldShowBanner] = useState(false);

    const COOKIE_EXPIRY_HOURS = 24; // 24 hours


    const has24HoursPassed = () => {
        const declinedAt = localStorage.getItem('declinedAt');
        if (!declinedAt) return true;

        const now = new Date().getTime();
        const timeDifference = now - parseInt(declinedAt);
        const hoursDifference = timeDifference / (1000 * 60 * 60);

        return hoursDifference >= COOKIE_EXPIRY_HOURS;
    };

    useEffect(() => {
        const consent = localStorage.getItem('analyticsConsent');

        if (consent === 'true') {
            setAnalyticsEnabled(true);
        } else if (consent === 'false') {
            setShouldShowBanner(has24HoursPassed());
        } else {
            setShouldShowBanner(true);
        }
    }, []);

    useEffect(() => {
        if (analyticsEnabled) {
            ReactGA.initialize('G-YN4H1DCGQ5');
            ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
        }
    }, [analyticsEnabled, location]);

    ScrollToTop();


    const handleAllowCookies = () => {
        setAnalyticsEnabled(true);
        localStorage.setItem('analyticsConsent', 'true');
        localStorage.removeItem('declinedAt');
        setShouldShowBanner(false);
    };

    const handleDeclineCookies = () => {
        setAnalyticsEnabled(false);
        localStorage.setItem('analyticsConsent', 'false');
        localStorage.setItem('declinedAt', new Date().getTime());
        setShouldShowBanner(false);
    };

    return (
        <>
            {location?.pathname === '/' ? <Navbar mode="home" /> : <Navbar />}

            <Outlet />

            {grayFooterPaths.includes(location.pathname) ? <Footer color="gray" /> : <Footer />}

            {/* cookies banner */}
            {shouldShowBanner && (
                <div className="z-30 size-full fixed top-0 left-0 bg-black/50 select-none">
                    <div className="sm:px-10 px-5 w-full mt-auto flex fixed bottom-10">
                        <div className="sm:px-10 px-5 py-5 border w-full md:flex justify-between items-center bg-white rounded-2xl sm:text-base text-sm">
                            <p className="md:me-10">
                                This website uses cookies to ensure you get the best experience on our website.{' '}
                                <span className="text-blue-500 underline">Learn more</span>
                            </p>
                            <div className="mt-5 md:mt-0 text-nowrap text-end">
                                <button className="sm:p-2 p-1 sm:px-5 px-3 me-5 border rounded hover:border-black" onClick={handleDeclineCookies}>
                                    Decline
                                </button>
                                <button className="sm:p-2 p-1 sm:px-5 px-3 bg-blue hover:bg-blue-600 text-white rounded text-nowrap" onClick={handleAllowCookies}>
                                    Allow Cookies
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
