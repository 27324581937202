import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from '../shared/Layout';
import Loader from '../shared/Loader';
import ErrorBoundary from '../utility/ErrorBoundary';

const Home = lazy(() => import('../component/Home'));
const AboutUs = lazy(() => import('../component/AboutUs'));
const Work = lazy(() => import('../component/Work'));
const Services = lazy(() => import('../component/Services'));
const Blog = lazy(() => import('../component/Blog'));
const BlogInternal = lazy(() => import('../component/BlogInternal'));
const ContactUs = lazy(() => import('../component/ContactUs'));
const ContactUs2 = lazy(() => import('../component/ContactUs2'));


function Routing() {
  const routing = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          element: <ErrorBoundary />,
          children: [
            {
              path: '/',
              element: (
                <Suspense fallback={<Loader />}>
                  <Home />
                </Suspense>
              ),
            },
            {
              path: '/AboutUs',
              element: (
                <Suspense fallback={<Loader />}>
                  <AboutUs />
                </Suspense>
              ),
            },
            {
              path: 'Work',
              element: (
                <Suspense fallback={<Loader />}>
                  <Work />
                </Suspense>
              ),
            },
            {
              path: 'Services',
              element: (
                <Suspense fallback={<Loader />}>
                  <Services />
                </Suspense>
              ),
            },
            {
              path: 'Blog',
              element: (
                <Suspense fallback={<Loader />}>
                  <Blog />
                </Suspense>
              ),
            },
            {
              path: 'BlogInternal',
              element: (
                <Suspense fallback={<Loader />}>
                  <BlogInternal />
                </Suspense>
              ),
            },
            {
              path: 'ContactUs',
              element: (
                <Suspense fallback={<Loader />}>
                  <ContactUs />
                </Suspense>
              ),
            },
            {
              path: 'ContactUs2',
              element: (
                <Suspense fallback={<Loader />}>
                  <ContactUs2 />
                </Suspense>
              ),
            }
          ]
        },
      ],

    },
  ]);

  return (
    <RouterProvider router={routing} />
  );
}

export default Routing;
