import React from "react";
import { Outlet } from "react-router-dom";
import aboutItem4 from '../assets/img/aboutItem4.png'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <>
                <div className="h-screen w-full flex flex-col items-center justify-center bg-blue1 text-white">
                    <img src={aboutItem4} alt="" className="rotate-element md:h-[300px] h-[150px]"/>
                    <h1 className="title2 text-center text-gray1 mt-10">Something went wrong.</h1>
                </div>
            </>
        } else {
            return <Outlet />;
        }
    }
}

export default ErrorBoundary;
