import Routing from "./router/Routing";

function App() {

  return (
    <div className="select-none">
      <Routing />
    </div>
  );
}

export default App;
