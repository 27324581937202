import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import loaderAnimation from '../assets/animation/cubezyLogo.json';

const Loader = () => {
    return (
        <>
            <div className="h-full w-full fixed top-0 left 0 flex justify-center items-center bg-black/70 z-30 select-none">
                <Player
                    autoplay
                    loop
                    src={loaderAnimation}
                    style={{ height: '300px', width: '300px' }}
                />
            </div>
        </>
    );
};

export default Loader;
